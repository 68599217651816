.paywall-container {
  max-width: 600px;
  margin: auto;
}

.paywall-header {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 2em;
}

.plans-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plan {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  color: #333;
  text-align: center;
}

.plan-content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.plan-name {
  font-weight: bold;
  font-size: 1.2em;
}

.plan-price {
  color: #333;
  font-size: 1em;
}

.payment-form {
  margin-top: 30px;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 20px;
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-button:hover {
  background-color: #0056b3;
}

.paywall-container {
  display: flex;
  justify-content: center;  /* align horizontally in center */
  align-items: center;      /* align vertically in center */
  height: 100vh;            /* take the full viewport height */
  width: 100vw;             /* take the full viewport width */
  position: relative;       /* relative positioning */
  overflow: hidden;         /* prevent overflow */
  background-color: #ffffff;  /* Background color, adjust as needed */
}

/* Position the text above the Lottie animation */
.centered-text {
  position: absolute;
  top: 50%;  /* center vertically */
  left: 50%;  /* center horizontally */
  transform: translate(-50%, -50%);
  z-index: 10;
}
